import { FC } from "react";
import Image from "next/image";
import dayjs from "dayjs";
import Link from "next/link";
import { Course } from "@/fetch/academy";
import { ClickableCard } from "@/components/Card";
import Typography from "@/components/Typography";
import Avatar, { AvatarGroup } from "@/components/Avatar";
import Tooltip from "@/components/Tooltip";
import styles from "./CourseCard.module.scss";

type Props = {
  course: Course;
};

const CourseCard: FC<Props> = ({ course }) => {
  const bannerImage = `https://res.cloudinary.com/gwatco/image/upload/f_auto,q_auto,h_180,dpr_2.0/${course.cover}.png`;
  const duration = dayjs
    .duration(course.total_length_in_min, "minutes")
    .format("H[h] m[m]")
    .replace(/\b0+[a-z]+\s*/gi, "")
    .trim();

  const avatarImageUrl = course.enrolments.sample_enrolments
    .slice(0, 3)
    .map(
      (each) =>
        `https://res.cloudinary.com/gwatco/image/upload/ar_1.0,c_thumb,g_auto/${each.s3_profile_pic}`
    );

  const AvatarTooltip = () => {
    const showingEnrolments = course.enrolments.sample_enrolments.slice(0, 4);
    const remainingEnrolments = course.enrolments.total_enrolments - 4;

    return (
      <div className={styles.tooltipContainer}>
        {showingEnrolments.map((each) => (
          <div key={each.cid} className={styles.tooltipItem}>
            <Avatar
              src={`https://res.cloudinary.com/gwatco/image/upload/ar_1.0,c_thumb,g_auto/${each.s3_profile_pic}`}
              size="s"
            />
            <Typography
              variant="body2"
              color="text.contrast"
              className={styles.nickName}
            >
              {each.nickname}
            </Typography>
          </div>
        ))}
        {remainingEnrolments > 0 && (
          <Typography variant="body2" color="text.contrast">
            {`+${remainingEnrolments}`}
          </Typography>
        )}
      </div>
    );
  };

  return (
    <ClickableCard className={styles.root}>
      <Link href={`/academy/${course.id}`} passHref>
        <div className={styles.container}>
          <div className={styles.bannerImage}>
            <Image
              src={bannerImage}
              alt={course.title}
              layout="fill"
              objectFit="cover"
            />
          </div>
          <div className={styles.content}>
            <Typography variant="body1">{course.title}</Typography>
            {course.enrolments.total_enrolments > 0 && (
              <Tooltip title={<AvatarTooltip />} placement="right" arrow>
                <div className={styles.avatarsContainer}>
                  <AvatarGroup
                    imageUrls={avatarImageUrl}
                    AvatarProps={{ size: "s" }}
                  />
                  {course.enrolments.total_enrolments - 3 > 0 && (
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className={styles.enrolmentCount}
                    >
                      {`+${course.enrolments.total_enrolments - 3}`}
                    </Typography>
                  )}
                </div>
              </Tooltip>
            )}
            <div className={styles.bottomContainer}>
              <Typography color="text.secondary" variant="body2">
                {course.categories.map((each) => each.name).join(", ")}
              </Typography>
              <Typography color="text.secondary" variant="body2">
                {duration}
              </Typography>
            </div>
          </div>
        </div>
      </Link>
    </ClickableCard>
  );
};

export default CourseCard;
