import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { ACADEMY_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { Category } from "./useCategoriesQuery";
import { string } from "yup";

type QueryError = {};

type Result = {
  response: {
    message: string;
    urls: {
      poster: string;
      [`video/mp4`]: string;
      [`video/ogg`]: string;
      [`video/webm`]: string;
    };
  };
  statusCode: number;
  success: boolean;
};

const useCourseVideoUrlQuery = (
  stepId: number,
  options?: UseQueryOptions<Result, QueryError, Result, QueryKey>
) => {
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const URL = `${ACADEMY_ENDPOINT}/steps/${stepId}/get-signed-url`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [URL];

  return useQuery<Result, QueryError>(
    cacheKey,
    async () => {
      if (!cid || !stepId) throw new Error();
      const res = await fetchWithAuth<Result>(URL);
      return res;
    },
    {
      enabled: !!cid && !!stepId,
      ...options,
      cacheTime: 1,
    }
  );
};

export default useCourseVideoUrlQuery;
