import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { ACADEMY_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type Category = {
  id: number;
  name: string;
  color: string;
};

type Categories = {
  response: Array<Category>;
  statusCode: number;
  success: boolean;
};

type QueryError = {};

const useCategoriesQuery = (
  options?: UseQueryOptions<Categories, QueryError, Categories, QueryKey>
) => {
  const URL = `${ACADEMY_ENDPOINT}/categories`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [URL];

  return useQuery<Categories, QueryError>(
    cacheKey,
    async () => {
      const res = await fetchWithAuth<Categories>(URL);
      return res;
    },
    {
      ...options,
    }
  );
};
export default useCategoriesQuery;
