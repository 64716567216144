import { FC } from "react";
import Image from "next/image";
import Link from "next/link";
import { CourseProgress } from "@/fetch/academy";
import { ClickableCard } from "@/components/Card";
import Typography from "@/components/Typography";
import { PlayCircleIcon } from "@/components/Icon";
import { LinearProgress } from "@/components/Loader";
import cx from "classnames";
import styles from "./CourseProgressCard.module.scss";

type Props = {
  course: CourseProgress;
  className?: string;
};

const CourseProgressCard: FC<Props> = ({ course, className }) => {
  const bannerImage = `https://res.cloudinary.com/gwatco/image/upload/f_auto,q_auto,h_180,dpr_2.0/${course.cover}.png`;

  return (
    <Link href={`/academy/${course.id}`} passHref>
      <ClickableCard className={cx(className, styles.root)}>
        <div className={styles.container}>
          <div className={styles.bannerImage}>
            <Image
              src={bannerImage}
              alt={course.title}
              layout="fill"
              objectFit="cover"
            />
          </div>
          <div className={styles.content}>
            <Typography variant="body1">{course.title}</Typography>

            <div className={styles.progressStepsContainer}>
              <div className={styles.stepsContainer}>
                <PlayCircleIcon className={styles.playIcon} />
                <Typography variant="body2" color="text.secondary">
                  {`${course.completed_steps}/${course.total_steps} lessons completed`}
                </Typography>
              </div>
              <LinearProgress
                variant="determinate"
                value={(course.completed_steps / course.total_steps) * 100}
                className={styles.progress}
              />
            </div>

            <Typography color="text.secondary" variant="body2">
              {course.categories.map((each) => each.name).join(", ")}
            </Typography>
          </div>
        </div>
      </ClickableCard>
    </Link>
  );
};

export default CourseProgressCard;
