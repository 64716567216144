import { FC, Fragment } from "react";
import dayjs from "dayjs";
import cx from "classnames";
import { Section, StepType, Step } from "@/fetch/academy";
import Typography from "@/components/Typography";
import { PlayCircleIcon, FileIcon, TickIcon } from "@/components/Icon";
import styles from "./CourseSections.module.scss";

type Props = {
  sections: Array<Section>;
  selectedStepId: number;
  selectStep: (newStep: Step) => void;
};

const CourseSections: FC<Props> = ({
  sections,
  selectedStepId,
  selectStep,
}) => {
  const getStepDuration = (minutes: number) => {
    return dayjs
      .duration(minutes, "minutes")
      .format(minutes > 59 ? "HH:mm:ss" : "mm:ss");
  };

  return (
    <div className={styles.root}>
      {sections.map((eachSection, idx) => {
        const sortedSteps = [...(eachSection.steps || [])].sort(
          (firstStep, secondStep) => firstStep.order - secondStep.order
        );
        return (
          <Fragment key={eachSection.id}>
            <Typography
              variant="body1"
              color="text.secondary"
              className={styles.sectionText}
            >
              {`${idx + 1}. ${eachSection.name}`}
            </Typography>
            {sortedSteps.map((eachStep) => (
              <div
                key={eachStep.id}
                className={cx(styles.stepContainer, {
                  [styles.selectedStep]: eachStep.id === selectedStepId,
                })}
                role="presentation"
                onClick={() => selectStep(eachStep)}
              >
                {eachStep.type === StepType.Video && (
                  <PlayCircleIcon
                    className={cx(styles.stepIcon, {
                      [styles.stepSeenIcon]: eachStep.step_is_seen,
                    })}
                  />
                )}
                {eachStep.type === StepType.Text && (
                  <FileIcon
                    className={cx(styles.stepIcon, {
                      [styles.stepSeenIcon]: eachStep.step_is_seen,
                    })}
                  />
                )}
                <Typography variant="subtitle1" color="text.contrast">
                  {eachStep.name}
                </Typography>
                <Typography
                  variant="body1"
                  color={
                    eachStep.step_is_seen ? "text.contrast" : "text.secondary"
                  }
                  className={styles.durationText}
                >
                  {eachStep.type === StepType.Video
                    ? getStepDuration(eachStep.length_in_min)
                    : ""}
                </Typography>
                {eachStep.step_is_seen ? (
                  <TickIcon className={styles.checkIcon} />
                ) : (
                  <div className={styles.checkIconPlaceHolder} />
                )}
              </div>
            ))}
          </Fragment>
        );
      })}
    </div>
  );
};

export default CourseSections;
