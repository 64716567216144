import { FC, useMemo, useRef, useState } from "react";
import Player, { APITypes, MediaType, PlayerProps } from "@/components/Player";
import { useCourseVideoUrlQuery } from "@/fetch/academy";
import styles from "./VideoPreview.module.scss";

const VideoPreview: FC<
  Omit<PlayerProps, "source"> & {
    stepId: number;
    onProgressUpdate: (progresss: number) => void;
    trackerData?: { [key: string]: string | number | undefined };
  }
> = ({ stepId, onProgressUpdate, ...props }) => {
  const playerRef = useRef<APITypes | null>(null);
  const { data, isLoading } = useCourseVideoUrlQuery(stepId, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
  const signedUrlData = data?.response.urls;
  const [progress, setProgress] = useState<number>(0);
  const [isOnReachEnd, setIsOnReachEnd] = useState(false);
  const [isReachedTenPercent, setIsReachedTenPercent] =
    useState<boolean>(false);

  const onTimeUpdate = (plyr: Plyr.PlyrEvent) => {
    const currentTime = plyr.detail?.plyr.currentTime;
    const duration = plyr.detail?.plyr.duration;
    const currentPercentage = (currentTime || 0) / (duration || 1);
    const stepPercentage = Math.floor(currentPercentage * 10);
    if (progress < stepPercentage) {
      onProgressUpdate(stepPercentage / 10);
      setProgress(stepPercentage);
    }
  };

  const source = useMemo(() => {
    return {
      type: "video" as MediaType,
      sources: [
        // {
        //   type: "video/webm",
        //   src: signedUrlData?.["video/webm"] || "",
        // },
        {
          type: "video/mp4",
          src:
            signedUrlData?.["video/mp4"]?.replace("http://", "https://") || "",
        },
        {
          type: "video/ogg",
          src: signedUrlData?.["video/ogg"] || "",
        },
      ],
    };
  }, [signedUrlData]);

  return (
    <div className={styles.root}>
      {isLoading ? (
        <span></span>
      ) : (
        <Player
          {...props}
          ref={playerRef}
          poster={signedUrlData?.poster}
          onTimeUpdate={onTimeUpdate}
          source={source}
        />
      )}
    </div>
  );
};

export default VideoPreview;
