import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "react-query";
import { ACADEMY_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { Category } from "./useCategoriesQuery";

export enum SortValues {
  alphabetical = "alphabetical",
  watched = "watched",
  added = "added",
  popularity = "popularity",
}

export enum ProgressValues {
  AllCourses = 1,
  InProgress = 2,
  NotStarted = 3,
  Completed = 4,
}

export type Enrolments = {
  total_enrolments: number;
  sample_enrolments: {
    cid: number;
    course_id: number;
    email: string;
    nickname: string;
    s3_profile_pic: string;
  }[];
};

export type Course = {
  id: number;
  title: string;
  description: string;
  cover: string;
  total_sections: number;
  total_steps: number;
  total_video_steps: number;
  total_text_steps: number;
  total_length_in_min: number;
  categories: Category[];
  enrolments: Enrolments;
  overview: string;
};

type Result = {
  response: { results: Array<Course>; totalCount: number };
  statusCode: number;
  success: boolean;
};

type QueryError = {};

type Variables = {
  pageSize: number;
  pageNum: number;
  keyword?: string;
  categoryIds?: string;
  progress?: string;
  sort: SortValues;
};

export type PaginatedItemsQueryResult = {
  results?: Array<Course>;
  totalCount?: number;
};

export type PaginatedItemsFetchResult = {
  response: PaginatedItemsQueryResult;
  statusCode: number;
  success: boolean | string;
};

const usePaginatedItemsQuery = (
  variables: Variables,
  options?: UseInfiniteQueryOptions<
    PaginatedItemsQueryResult,
    any,
    PaginatedItemsQueryResult,
    PaginatedItemsQueryResult,
    QueryKey
  >
) => {
  const { fetchWithAuth } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const url = `${ACADEMY_ENDPOINT}/courses/traveller/${cid}`;
  const cacheKey = [url, "paginated", ...Object.entries(variables)];

  const queryResult = useInfiniteQuery<PaginatedItemsQueryResult, QueryError>(
    cacheKey,
    async ({ pageParam = 1 }) => {
      const params: any = {
        addEnrolments: true,
        ...Object.fromEntries(
          Object.entries(variables).filter(([key, value]) => Boolean(value))
        ),
      };
      const result = await fetchWithAuth<PaginatedItemsFetchResult>(url, {
        params: { ...params, pageNum: pageParam },
      });
      return result.response;
    },
    {
      ...options,
      getNextPageParam: (lastPage, pages) => {
        if ((lastPage?.totalCount || 0) >= variables.pageSize * pages.length)
          return pages?.length + 1;
        return undefined;
      },
    }
  );
  return { ...queryResult, isLoading: queryResult.isLoading };
};
export default usePaginatedItemsQuery;
