import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { ACADEMY_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { Category } from "./useCategoriesQuery";

export enum SortValues {
  alphabetical = "alphabetical",
  watched = "watched",
  added = "added",
  popularity = "popularity",
}

export enum ProgressValues {
  AllCourses = 1,
  InProgress = 2,
  NotStarted = 3,
  Completed = 4,
}

export type Enrolments = {
  total_enrolments: number;
  sample_enrolments: {
    cid: number;
    course_id: number;
    email: string;
    nickname: string;
    s3_profile_pic: string;
  }[];
};

export type Course = {
  id: number;
  title: string;
  description: string;
  cover: string;
  total_sections: number;
  total_steps: number;
  total_video_steps: number;
  total_text_steps: number;
  total_length_in_min: number;
  categories: Category[];
  enrolments: Enrolments;
  overview: string;
};

type Result = {
  response: { results: Array<Course>; totalCount: number };
  statusCode: number;
  success: boolean;
};

type QueryError = {};

type Variables = {
  pageSize: number;
  pageNum: number;
  keyword?: string;
  categoryIds?: string;
  progress?: string;
  sort: SortValues;
};

const useCoursesQuery = (
  variables: Variables,
  options?: UseQueryOptions<Result, QueryError, Result, QueryKey>
) => {
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const url = `${ACADEMY_ENDPOINT}/courses/traveller/${cid}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [url, JSON.stringify(variables)];

  return useQuery<Result, QueryError>(
    cacheKey,
    async () => {
      const params: any = {
        addEnrolments: true,
        ...Object.fromEntries(
          Object.entries(variables).filter(([key, value]) => Boolean(value))
        ),
      };

      const res = await fetchWithAuth<Result>(url, {
        params,
      });
      return res;
    },
    {
      enabled: !!cid,
      ...options,
    }
  );
};
export default useCoursesQuery;
