import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { ACADEMY_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { Category } from "./useCategoriesQuery";

export enum Progress {
  "AllCourses" = 1,
  "InProgress",
  "NotStarted",
  "Completed",
}

export type CourseProgress = {
  completed_steps: number;
  cover: string;
  id: number;
  last_watched: number;
  title: string;
  total_steps: number;
  categories: Category[];
  enabled: number;
  overview: string;
};

type Result = {
  response: { results: Array<CourseProgress>; totalCount: number };
  statusCode: number;
  success: boolean;
};

type QueryError = {};

const useCourseProgressesQuery = (
  options?: UseQueryOptions<Result, QueryError, Result, QueryKey>
) => {
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const URL = `${ACADEMY_ENDPOINT}/courses-progress/traveller/${cid}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [URL];

  return useQuery<Result, QueryError>(
    cacheKey,
    async () => {
      if (!cid) throw new Error();
      const res = await fetchWithAuth<Result>(URL);
      return res;
    },
    {
      enabled: !!cid,
      ...options,
    }
  );
};
export default useCourseProgressesQuery;
