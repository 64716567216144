import { FC, useEffect } from "react";
import { useRouter } from "next/router";
import Dialog, {
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@/components/Dialog";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import { SubmissionTypeItem } from "@/fetch/rewards";
import styles from "./LockedDialog.module.scss";
import { useTrackers } from "@/hooks";

type Props = {};

const LockedDialog: FC<Props> = ({}) => {
  const { push } = useRouter();
  const { track } = useTrackers();

  useEffect(() => {
    track("Course Locked Dialog Opened", {
      eventId: "course-locked-dialog-opened",
    });
  }, []);

  const onPay = () => {
    track("Course Locked Dialog Payment Initiated", {
      eventId: "course-locked-dialog-payment-initiated",
    });
    push("/experience/payments/invoice/trip");
  };

  const handleClose = () => {
    track("Course Locked Dialog Left", {
      eventId: "course-locked-dialog-left",
    });
    push("/academy");
  };

  return (
    <Dialog open>
      <DialogContent className={styles.dialogContent}>
        <Typography variant="h6">Unlock over a dozen courses!</Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          className={styles.description}
        >
          Finish your trip payments to gain exclusive access to the Global
          Academy. Dive into our ever-growing collection of video courses to
          enrich your skills and knowledge beyond the journey itself. Including
          language learning, workplace skills & more!
        </Typography>
      </DialogContent>
      <DialogActions className={styles.actionContainer}>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="info"
          size="small"
          className={styles.cancelButton}
        >
          I&apos;m not ready yet
        </Button>
        <Button
          onClick={onPay}
          variant="contained"
          color="primary"
          size="small"
        >
          Start learning
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LockedDialog;
