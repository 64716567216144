import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { ACADEMY_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export type CaptureActivityVariables = {
  stepId: number;
  progress: number;
};

type CaptureActivityResult = {
  response: {
    affectedRows: number;
    changedRows: number;
    fieldCount: number;
    insertId: number;
    message: string;
    protocol41: boolean;
    serverStatus: number;
    warningCount: number;
  };
  statusCode: number;
  success: boolean;
};

type QueryError = {};

const useCaptureActivityMutation = (
  courseId: number,
  options?: UseMutationOptions<
    CaptureActivityResult,
    QueryError,
    CaptureActivityVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const queryClient = useQueryClient();
  const courseCacheKey = `${ACADEMY_ENDPOINT}/courses/${courseId}/traveller/${cid}`;

  return {
    ...useMutation<CaptureActivityResult, QueryError, CaptureActivityVariables>(
      async (inputObj: CaptureActivityVariables) => {
        const url = `${ACADEMY_ENDPOINT}/activities`;
        const inputData = {
          step_id: inputObj.stepId,
          progress: inputObj.progress,
          cid,
        };

        return fetchWithAuth<CaptureActivityResult>(url, {
          method: "POST",
          body: inputData,
        });
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(courseCacheKey);
        },
        ...options,
      }
    ),
    cancel,
  };
};

export default useCaptureActivityMutation;
