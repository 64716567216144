import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { ACADEMY_ENDPOINT } from "./constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { Category } from "./useCategoriesQuery";

type QueryError = {};

export enum StepType {
  Text = "Text",
  Video = "Video",
}

export type Step = {
  content: string;
  description: string;
  id: number;
  length_in_min: number;
  name: string;
  order: number;
  step_is_seen: number;
  type: StepType;
};

export type Section = {
  id: number;
  name: string;
  order: number;
  steps: Array<Step>;
};

type Course = {
  categories: Array<Category>;
  cover: string;
  description: string;
  enabled: number;
  id: number;
  overview: string;
  sections: Array<Section>;
  title: string;
  total_length_in_min: number;
  total_sections: number;
  total_steps: number;
  total_text_steps: number;
  total_video_steps: number;
};

type Result = {
  response: Array<Course>;
  statusCode: number;
  success: boolean;
};

const useCourseQuery = (
  courseId: string,
  options?: UseQueryOptions<Result, QueryError, Result, QueryKey>
) => {
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const URL = `${ACADEMY_ENDPOINT}/courses/${courseId}/traveller/${cid}`;
  const { fetchWithAuth } = useFetchWithAuth();
  const cacheKey = [URL];

  return useQuery<Result, QueryError>(
    cacheKey,
    async () => {
      if (!cid || !courseId) throw new Error();
      const res = await fetchWithAuth<Result>(URL);
      return res;
    },
    {
      enabled: !!cid && !!courseId,
      ...options,
    }
  );
};

export default useCourseQuery;
